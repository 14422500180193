// JsFromRoutes CacheKey a06a4400c4d627e7e00e90b5cd89482d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/drive'),
  requestAccessToDocument: definePathHelper('post', '/drive/:id/request_access_to_document'),
  addCurrentUserAsParticipant: definePathHelper('post', '/drive/:id/add_current_user_as_participant'),
  templatePackDocuments: definePathHelper('get', '/drive/:id/template_pack_documents'),
}
