// JsFromRoutes CacheKey 32dbb0f6d8779605dac7fb8f951128df
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  chat: definePathHelper('post', '/ai/chat'),
  action: definePathHelper('get', '/ai/action'),
  clear: definePathHelper('post', '/ai/clear'),
  reviewXlsx: definePathHelper('post', '/ai/review_xlsx'),
  docxToEntries: definePathHelper('post', '/ai/docx_to_entries'),
  buildOperation: definePathHelper('post', '/ai/build_operation'),
  generateQuestions: definePathHelper('post', '/ai/generate_questions'),
  amendText: definePathHelper('post', '/ai/amend_text'),
}
